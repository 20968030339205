import { StatusCodes } from "http-status-codes";
import { defu } from "defu";

export function useRequest(baseUrl?: string) {
  const defaultOptions = {
    timeout: 10000,
    retry: 2,
    retryStatusCodes: [
      StatusCodes.REQUEST_TIMEOUT,
      425,
      StatusCodes.TOO_MANY_REQUESTS,
      StatusCodes.INTERNAL_SERVER_ERROR,
      StatusCodes.BAD_GATEWAY,
      StatusCodes.SERVICE_UNAVAILABLE,
      StatusCodes.GATEWAY_TIMEOUT,
    ],
    baseURL: baseUrl,
    headers: {
      "x-client-type": "web",
    },
  };

  function fetch<T>(request: Parameters<typeof $fetch<T>>[0], options?: Parameters<typeof $fetch<T>>[1]) {
    const opts = defu(options, defaultOptions) as Parameters<typeof $fetch<T>>[1];
    return $fetch<T>(request, opts);
  }

  function useNuxtFetch<T>(request: Parameters<typeof useFetch<T>>[0], options?: Parameters<typeof useFetch<T>>[1]) {
    const opts = defu(options, defaultOptions) as Parameters<typeof useFetch<T>>[1];
    return useFetch<T>(request, opts);
  }

  return {
    defaultOptions,
    fetch,
    useNuxtFetch,
  };
}

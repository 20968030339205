import type {
  ActionEvent,
  BiTrackingEvents,
  BiTrackingHeader,
  BiTrackingRequestBody,
  BiTrackingResponseData,
  GenericEvent,
  ImpressionEvent,
  ImpressionEventParams,
  PurchaseEvent,
  PurchaseEventParams,
} from "~/types/biTracking";
import { BiTrackingEventType } from "~/types/biTracking";
import { useBackendApiFrontendDomainConfig } from "~/composables/api/useBackendApiFrontendDomainConfig";
import { calculateBMI } from "~/utils/bmi";

export function useBiTrackingClient() {
  const { $pinia, $i18n } = useNuxtApp();
  const { apiBaseUrl } = useBackendApiFrontendDomainConfig();

  const biTrackingStore = useBiTrackingStore($pinia);
  const onboardingStore = useOnboardingStore($pinia);
  const checkoutStore = useCheckoutStore($pinia);

  const { utm, heyflowId } = storeToRefs(biTrackingStore);

  const { getAge } = useAge();
  const { fetch } = useRequest(apiBaseUrl);

  function trackGeneric(name: string, properties?: Record<string, string | string[] | number | boolean>) {
    const event: GenericEvent = {
      ...makeEventSharedParams(),
      type: BiTrackingEventType.GENERIC,
      name,
      properties: JSON.stringify(properties ?? {}),
    };
    return trackEvents([event]);
  }

  function trackAction(name: string, properties?: Record<string, string | string[] | number | boolean>) {
    const event: ActionEvent = {
      ...makeEventSharedParams(),
      type: BiTrackingEventType.ACTION,
      name,
      properties: JSON.stringify(properties ?? {}),
    };
    return trackEvents([event]);
  }

  function trackImpression(params: ImpressionEventParams) {
    const properties = {
      web_funnel_id: params.webFunnelId,
      login_status: params.loginStatus,
    };

    const filteredProperties = Object.fromEntries(
      Object.entries(properties).filter(([_, value]) => value !== undefined),
    );

    const event: ImpressionEvent = {
      ...makeEventSharedParams(),
      type: BiTrackingEventType.IMPRESSION,
      name: params.name ?? "",
      ...(Object.keys(filteredProperties).length > 0 ? { properties: JSON.stringify(filteredProperties) } : {}),
    };

    return trackEvents([event]);
  }

  function trackPurchase(params: PurchaseEventParams) {
    const event: PurchaseEvent = {
      ...makeEventSharedParams(),
      type: BiTrackingEventType.PURCHASE,
      duration: {
        isoPeriod: params.period,
      },
      sku: params.sku,
      currency: params.currency,
      gateway: params.gateway,
      amountCustomerFacingCurrency: params.amountCustomerFacingCurrency,
      properties: JSON.stringify({ switchedSession: params.switchedSession, paymentMethod: params.paymentMethod ? params.paymentMethod : undefined }),
    };

    return trackEvents([event]);
  }

  function makeEventSharedParams() {
    return {
      sessionId: biTrackingStore.sessionId,
      date: {
        isoDateTime: new Date().toISOString(),
      },
    };
  }

  const header = computed<BiTrackingHeader>(() => ({
    age: onboardingStore.registrationParams.dateOfBirth ? getAge(onboardingStore.registrationParams.dateOfBirth) : null,
    platform: "web",
    language: $i18n.locale.value,
    country: onboardingStore.registrationParams.country ?? null,
    sex: onboardingStore.registrationParams.sex ?? null,
    overallGoal: onboardingStore.registrationParams.goal ?? null,
    weightGoal: onboardingStore.registrationParams.weightGoal ?? null,
    weightCurrent: onboardingStore.registrationParams.startWeight ?? null,
    bodyMassIndex:
      onboardingStore.registrationParams.startWeight && onboardingStore.registrationParams.bodyHeight
        ? calculateBMI(onboardingStore.registrationParams.startWeight, onboardingStore.registrationParams.bodyHeight)
        : null,
    userUUID: onboardingStore.registrationParams.userUuid ?? null,
    properties: JSON.stringify({
      stripe_elements: true,
      mediasource: utm.value.utm_medium ?? "",
      channel: utm.value.utm_source ?? "",
      campaignid: utm.value.utm_campaign ?? "",
      adsetid: utm.value.utm_term ?? "",
      adid: utm.value.utm_content ?? "",
      heyflow_id: heyflowId.value ?? "",
      skipped_onboarding: onboardingStore.skippedOnboarding,
      trial_days: checkoutStore?.checkoutParams?.trial_days ?? null,
      user_agent: navigator?.userAgent ?? "",
    }),
  }));

  function trackEvents(events: BiTrackingEvents) {
    const body: BiTrackingRequestBody = [
      {
        header: header.value,
        events,
      },
    ];

    return fetch<BiTrackingResponseData>("/api/epr", {
      method: "post",
      headers: {
        "Content-Type": "application/vnd.yazio.events+json",
      },
      body: JSON.stringify(body),
    });
  }

  return {
    trackGeneric,
    trackImpression,
    trackPurchase,
    trackAction,
  };
}

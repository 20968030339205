import { ref } from "vue";
import { defineStore } from "pinia";
import { HoursToSeconds } from "@ilihub/time";
import type { UtmParameters } from "~/types/biTracking";
import { uuidv4 } from "~/utils/uuid";
import appConfig from "~/app.config";

import type { QueryParameters } from "~/utils/queryParams";
export const useBiTrackingStore = defineStore(
  "biTracking",
  () => {
    const heyflowId = ref<string>();
    const sessionId = ref<string>(uuidv4());
    const utm = ref<UtmParameters>({});

    function $reset() {
      heyflowId.value = undefined;
      sessionId.value = uuidv4();
      utm.value = {};
    }

    function handleQueryParameters(queryParameters: QueryParameters) {
      if (queryParameters.sessionShouldReset()) {
        $reset();
      }

      sessionId.value = queryParameters.getAndRemove("session_uuid") ?? sessionId.value;
      heyflowId.value = queryParameters.getAndRemove("flowid") ?? heyflowId.value;

      utm.value.utm_campaign = queryParameters.getAndRemove("utm_campaign") ?? utm.value.utm_campaign;
      utm.value.utm_content = queryParameters.getAndRemove("utm_content") ?? utm.value.utm_content;
      utm.value.utm_medium = queryParameters.getAndRemove("utm_medium") ?? utm.value.utm_medium;
      utm.value.utm_source = queryParameters.getAndRemove("utm_source") ?? utm.value.utm_source;
      utm.value.utm_term = queryParameters.getAndRemove("utm_term") ?? utm.value.utm_term;
    }

    return {
      $reset,
      heyflowId,
      utm,
      sessionId,
      handleQueryParameters,
    };
  },
  {
    persist: {
      pick: ["heyflowId", "sessionId", "utm"],
      storage: piniaPluginPersistedstate.cookies({
        maxAge: HoursToSeconds(appConfig.cookieMaxAgeH),
      }),
    },
  },
);

export const useCommonErrorMessage = () => {
  const { t, locale } = useNuxtApp().$i18n;
  const router = useRouter();

  const generalErrorMessage = computed<string>(() => {
    return t("app.general.error.general", ["https://help.yazio.com/hc/" + locale.value]);
  });

  const emailInvalid = computed<string>(() => {
    return t("app.validation.errors.email_format");
  });

  const emailEmpty = computed<string>(() => {
    return t("app.validation.errors.email_format");
  });

  function emailAlreadyExists(email: string) {
    const loginUrl = `${router.resolve("/login").href}?email=${encodeURIComponent(email)}`;
    return t("app.prosignup.register.error.email_exists", [loginUrl]);
  }

  const alreadyProMessage = computed<string>(() => {
    return t("app.prosignup.payment.error.alreadypro");
  });

  const anonymousUserMessage = computed<string>(() => {
    return t("app.propayment.step2.error.anonymous_user", ["https://help.yazio.com/hc/" + locale.value]);
  });

  const paymentFailedMessage = computed<string>(() => {
    return t("app.propayment.step3.error.paymentfailed", ["https://help.yazio.com/hc/" + locale.value]);
  });

  return {
    generalErrorMessage,
    emailInvalid,
    emailEmpty,
    emailAlreadyExists,
    alreadyProMessage,
    anonymousUserMessage,
    paymentFailedMessage,
  };
};

<script lang="ts" setup>
import EventTrackingHandler from "~/components/tracking/EventTrackingHandler.vue";

useGTM();

const { appEnv } = useRuntimeConfig().public;

useHead({
  titleTemplate: (titleChunk) => {
    if (appEnv !== "prod") {
      return `(${appEnv}) ${titleChunk}`;
    }
    return titleChunk ?? "";
  },
});
</script>

<template>
  <NuxtLayout>
    <ClientOnly>
      <EventTrackingHandler />
    </ClientOnly>
    <NuxtPage />
  </NuxtLayout>
</template>

export default defineAppConfig({
  goalKgPerWeek: 0.5,
  goalLbPerWeek: 1.1,
  cookieMaxAgeH: 120,
  trialDays: 7,
  authCookieMaxAgeD: 30,
  onboardingLoadingPersonalPlanMS: [3500, 2500, 2500, 2500],
  enabledFunnels: ["partner", "test", "welcome", "calculator"],
  awin: {
    defaultMerchantId: "29533",
    basketFreezeOverrideList: [
      "647297",
      "1265723",
      "1073895",
      "852688",
      "786479",
      "781237",
      "716289",
      "637873",
      "544429",
      "430631",
      "426667",
      "403267",
      "400137",
      "334031",
      "333885",
      "332747",
      "332727",
      "332701",
      "332681",
      "332677",
      "328727",
      "320221",
      "228811",
      "196639",
      "320119",
      "1237770",
      "1207960",
      "1164654",
      "1052075",
      "826657",
      "822461",
      "809055",
      "677893",
      "654885",
      "624495",
      "544429",
      "518347",
      "213861",
      "196639",
      "137879",
    ],
  },
  excludedDiscountCodesFromFreeTrialABTest: [
    "allenxspeigner",
    "anniversary-offer-yrp2y",
    "awfnr",
    "baywatch",
    "benefithub",
    "birthday-offer-bwp5t",
    "blatantreviews",
    "bluelightcard",
    "bradley-perry",
    "brandon-william",
    "carlyycamilla",
    "cleanplates",
    "cmgetfit",
    "coldones",
    "cookingwithmima",
    "dailybeast",
    "decathlon-es",
    "dickdoof",
    "dienervigen",
    "digt",
    "distil",
    "elle",
    "ellendeckerr",
    "emotions",
    "etepetete",
    "fitandwell",
    "fitwithnikk",
    "food-theorists",
    "foodbythegram",
    "foodcrimes",
    "foodies-stay-fit",
    "funnelplus",
    "futureparty",
    "gala",
    "gethegoods",
    "gigieats",
    "gotnexxt",
    "grazia",
    "guga",
    "gugafood",
    "healthnews",
    "healthyhoney",
    "hellofresh-es",
    "hellofresh-fr",
    "hellofresh-it",
    "hellofresh-us",
    "hth",
    "independent",
    "indubiopronina",
    "isabellacamille",
    "isic-kmx3v",
    "jayfit8",
    "joe-bartolozzi",
    "joincarers",
    "joincharity",
    "joinhealth",
    "jointeachers",
    "josievr",
    "karimcooks",
    "kingrackbar",
    "laurenbrazee",
    "lufyyyy",
    "mariahfit",
    "marieclaire",
    "mark-manson",
    "matt-stonie",
    "mayaandcarter",
    "mirror",
    "mishkamakesfood",
    "mivo",
    "moveitmeag",
    "myprotein",
    "nl2-uewgh",
    "ny25-qqc6y",
    "ok",
    "pillar4",
    "rewardgateaway",
    "rissyroo",
    "savoir",
    "shawnsmahal",
    "sometimes-keto-jrae",
    "stahl",
    "standard",
    "support-syr3s",
    "sylviahussein",
    "tag24",
    "techtimes",
    "telegraph",
    "thecoles",
    "thetastefuldiet",
    "tiboinshape",
    "topsante",
    "veganuary",
    "verbrechen",
    "victoriatschoppp",
    "vitalityviews",
    "www",
    "yz-mar2024",
    "yz-ny2024",
    "yz-sum2024",
  ],
});

declare module "nuxt/schema" {
  interface AppConfig {
    goalKgPerWeek: number;
    goalLbPerWeek: number;
    cookieMaxAgeH: number;
    trialDays: number;
    authCookieMaxAgeD: number;
    awin: {
      defaultMerchantId: string;
      basketFreezeOverrideList: string[];
    };
  }
}

import { ref } from "vue";
import { defineStore } from "pinia";
import { YearsToSeconds } from "@ilihub/time";
import appConfig from "~/app.config";

import type { QueryParameters } from "~/utils/queryParams";

export const useAwinStore = defineStore(
  "awin",
  () => {
    const awc = ref<string>("");
    const merchantId = ref<string>(appConfig.awin.defaultMerchantId);
    const affiliateId = ref<string>("");
    const source = ref<string>("web");

    const basketFreezeExpires = ref<string>("");
    const frozenAwc = ref<string>("");

    function handleQueryParameters(queryParameters: QueryParameters) {
      awc.value = queryParameters.get("awc") ?? awc.value;
      merchantId.value = queryParameters.getAndRemove("awinMerchantId") ?? merchantId.value;
      affiliateId.value = queryParameters.getAndRemove("affid") ?? affiliateId.value;
      source.value = queryParameters.getAndRemove("utm_source") ?? source.value;

      queryParameters.remove("sv_campaign_id");
      queryParameters.remove("sv1");
    }

    return {
      awc,
      source,
      merchantId,
      affiliateId,
      basketFreezeExpires,
      frozenAwc,
      handleQueryParameters,
    };
  },
  {
    persist: {
      storage: piniaPluginPersistedstate.cookies({
        maxAge: YearsToSeconds(1),
      }),
    },
  },
);

import { ref } from "vue";
import { defineStore } from "pinia";
import { HoursToSeconds } from "@ilihub/time";
import type { UpdateUserGoalsParams, UpdateUserParams, UserAccount } from "~/types/user";
import appConfig from "~/app.config";

import type { QueryParameters } from "~/utils/queryParams";

export type UserType = "guest" | "user" | "tokenUser";

export const useUserStore = defineStore(
  "user",
  () => {
    const { $pinia } = useNuxtApp();
    const { getCountry } = useCountry();
    const tokenUserStore = useTokenUserStore($pinia);
    const backendApiAppDomainClient = useBackendApiAppDomainClient();

    const { data: fetchUserData, execute: executeFetchUser } = backendApiAppDomainClient.useFetchUser();
    const { data: fetchUserGoalsData, execute: executeFetchUserGoals } = backendApiAppDomainClient.useFetchUserGoals();

    const user = ref<UserAccount>();

    const userType = computed<UserType>(() => {
      if (user.value) {
        return "user";
      }

      if (tokenUserStore.tokenUser) {
        return "tokenUser";
      }

      return "guest";
    });

    async function fetchUser() {
      await executeFetchUser();
      await executeFetchUserGoals();

      if (!fetchUserData.value || !fetchUserGoalsData.value) {
        return;
      }

      const country = fetchUserData.value.country || getCountry();
      const goal_weight =
        fetchUserGoalsData.value["bodyvalue.weight"] ?? Math.round(fetchUserData.value.start_weight * 0.9);

      user.value = { ...fetchUserData.value, country, goal_weight };
    }

    async function updateUser(params: Partial<UpdateUserParams>) {
      await backendApiAppDomainClient.updateUser(params);

      if (user.value) {
        user.value = { ...user.value, ...params };
      }
    }

    async function updateUserGoals(params: UpdateUserGoalsParams) {
      await backendApiAppDomainClient.updateUserGoals(params);
    }

    function handleQueryParameters(queryParameters: QueryParameters) {
      if (queryParameters.isNewFlow()) {
        $reset();
      }
    }

    function $reset() {
      user.value = undefined;
    }

    return {
      $reset,
      user,
      userType,
      fetchUser,
      updateUser,
      updateUserGoals,
      handleQueryParameters,
    };
  },
  {
    persist: {
      key: "user",
      storage: piniaPluginPersistedstate.cookies({
        maxAge: HoursToSeconds(appConfig.cookieMaxAgeH),
      }),
    },
  },
);

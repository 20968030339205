import { defineStore } from "pinia";
import { DaysToSeconds } from "@ilihub/time";
import appConfig from "~/app.config";

import type { QueryParameters } from "~/utils/queryParams";

export const useAuthStore = defineStore(
  "auth",
  () => {
    const { $pinia } = useNuxtApp();
    const tokenUserStore = useTokenUserStore($pinia);
    const userStore = useUserStore($pinia);

    const { getAccessToken } = useAuthApiClient();

    const accessToken = ref<string>();
    const refreshToken = ref<string>();
    const loginMethod = ref<"email" | "siwg" | "siwa">();

    async function refreshAuthTokens() {
      if (!refreshToken.value) {
        return;
      }
      try {
        const { accessToken: a, refreshToken: r } = await getAccessToken({
          refresh_token: refreshToken.value,
          grant_type: "refresh_token",
        });
        accessToken.value = a;
        refreshToken.value = r;
      } catch (_) {
        userStore.user = undefined;
        accessToken.value = undefined;
        refreshToken.value = undefined;
        loginMethod.value = undefined;
      }
    }

    async function login(username: string, password: string) {
      const { accessToken: a, refreshToken: r } = await getAccessToken({
        username,
        password,
        grant_type: "password",
      });

      // reset tokenUserStore to avoid conflicts
      tokenUserStore.$reset();

      loginMethod.value = "email";
      accessToken.value = a;
      refreshToken.value = r;
    }

    function $reset() {
      accessToken.value = undefined;
      refreshToken.value = undefined;
    }

    async function signInWithGoogle(googleToken: string) {
      const { accessToken: a, refreshToken: r } = await getAccessToken({
        id_token: googleToken,
        grant_type: "sign_in_with_google",
      });

      accessToken.value = a;
      refreshToken.value = r;
      loginMethod.value = "siwg";
    }

    async function signInWithApple(appleToken: string) {
      const { accessToken: a, refreshToken: r } = await getAccessToken({
        jwt: appleToken,
        grant_type: "sign_in_with_apple",
      });

      accessToken.value = a;
      refreshToken.value = r;
      loginMethod.value = "siwa";
    }

    function handleQueryParameters(queryParameters: QueryParameters) {
      if (queryParameters.isNewFlow()) {
        $reset();
      }
    }

    return {
      $reset,
      accessToken,
      refreshToken,
      loginMethod,
      refreshAuthTokens,
      login,
      signInWithGoogle,
      signInWithApple,
      handleQueryParameters,
    };
  },
  {
    persist: {
      key: "auth",
      storage: piniaPluginPersistedstate.cookies({
        maxAge: DaysToSeconds(appConfig.authCookieMaxAgeD),
      }),
    },
  },
);

import * as Sentry from "@sentry/nuxt";
import { ref } from "vue";
import { defineStore } from "pinia";
import { HoursToSeconds } from "@ilihub/time";
import type { CheckoutParams, GetSubscriptionPlansParams, SubscriptionPlan } from "~/types/backendApiClient";
import type { CheckoutSuccess } from "~/types/checkoutSuccess";
import appConfig from "~/app.config";
import type { QueryParameters } from "~/utils/queryParams";

export type UserState = "guest" | "user" | "token";

export const useCheckoutStore = defineStore(
  "checkout",
  () => {
    const backendApiClient = useBackendApiFrontendDomainClient();
    const { generalErrorMessage } = useCommonErrorMessage();
    const { trialDays } = useAppConfig();

    const checkoutParams = ref<CheckoutParams>(initialCheckoutParams());
    const checkoutSuccess = ref<CheckoutSuccess>();
    const availableSubscriptionPlansQuery = ref<GetSubscriptionPlansParams>();
    const selectedPlan = ref<SubscriptionPlan>();
    const stripeCheckoutPaymentMethodsElem = ref<HTMLElement | null>(null);
    const paymentMethodName = ref<string | undefined>();
    const browserRandomId = ref<string>(uuidv4());

    const { data: subscriptionPlans, execute: executeGetAvailableSubscriptionPlans } =
      backendApiClient.useGetAvailableSubscriptionPlans(availableSubscriptionPlansQuery);

    async function getAvailableSubscriptionPlans(query: GetSubscriptionPlansParams) {
      availableSubscriptionPlansQuery.value = query;
      await executeGetAvailableSubscriptionPlans();

      if (!subscriptionPlans.value || subscriptionPlans.value.length === 0) {
        Sentry.captureMessage("No subscriptions plans fetched from backend.", {
          extra: {
            segment: query?.segment ?? "unknown",
            country: query?.country ?? "unknown",
            locale: query?.locale ?? "unknown",
            user_token: query?.user_token ?? "unknown",
            server: process.server ? "yes" : "no",
          },
        });

        throw createError({
          message: generalErrorMessage.value,
        });
      }

      if (!selectedPlan.value) {
        selectedPlan.value = subscriptionPlans.value[0];
      }

      return subscriptionPlans.value;
    }

    function initialCheckoutParams(): CheckoutParams {
      return {
        trial_days: 0,
      };
    }

    function handleQueryParameters(queryParameters: QueryParameters) {
      if (queryParameters.sessionShouldReset()) {
        checkoutParams.value = initialCheckoutParams();
      }

      const trialParameter = queryParameters.getAndRemove("trial");

      if (trialParameter) {
        checkoutParams.value.trial_days = trialParameter === "0" ? 0 : trialDays;
      }
    }

    function $reset() {
      checkoutParams.value = initialCheckoutParams();
      checkoutSuccess.value = undefined;
    }

    function setTrial() {
      checkoutParams.value.trial_days = trialDays;
    }

    return {
      stripeCheckoutPaymentMethodsElem,
      subscriptionPlans,
      selectedPlan,
      checkoutParams,
      checkoutSuccess,
      paymentMethodName,
      browserRandomId,
      getAvailableSubscriptionPlans,
      handleQueryParameters,
      $reset,
      setTrial,
    };
  },
  {
    persist: {
      pick: ["checkoutParams", "checkoutSuccess", "paymentMethodName", "clientSecret"],
      storage: piniaPluginPersistedstate.cookies({
        maxAge: HoursToSeconds(appConfig.cookieMaxAgeH),
      }),
    },
  },
);

import { GrowthBook, configureCache } from "@growthbook/growthbook";
import { defineNuxtPlugin, useOnboardingStore, useRuntimeConfig } from "#imports";
import type { I18n } from "~/types/i18n";
import { useFeatureFlagStore } from "~/stores/useFeatureFlagStore";

const featureKeys = [
  "express_checkout",
  "tax_postalcode",
  "personalized_benefits_screen",
  "weight_loss_value_proposition",
  "partnership_paywall_colors",
  "free_trial_for_partnerships",
  "animated_fruits_questions",
  "zipcode_inside_stripe",
  "no_charge_yet_on_paywall",
  "skip_motivation_affirmation_screens",
] as const;

const stringFeatureKeys = [
  { key: "reposition_ratings_on_paywall", options: ["OFF", "END_OF_PAGE", "REMOVED"] },
] as const;

interface FeatureFlagOptions {
  silent?: boolean; // dont trigger the trackingCallback
  ignoreStore?: boolean; // give the actual result from growthbook and not the store value
}

export type GrowthBookFeatureFlags = Record<
  (typeof featureKeys)[number],
  {
    isOn: (options?: FeatureFlagOptions) => ComputedRef<boolean>;
    isOff: (options?: FeatureFlagOptions) => ComputedRef<boolean>;
  }
>;

export type GrowthBookStringFeatureFlags = Record<
  (typeof stringFeatureKeys)[number]["key"],
  {
    getStringValue: (
      options?: FeatureFlagOptions,
    ) => ComputedRef<(typeof stringFeatureKeys)[number]["options"][number]>;
    options: (typeof stringFeatureKeys)[number]["options"];
  }
>;

export default defineNuxtPlugin({
  name: "03.growthBook",
  dependsOn: ["i18n:plugin", "i18n:plugin:switch-locale-path-ssr", "01I18n", "pinia", "pinia-plugin-persistedstate"],
  async setup(nuxt) {
    // mock the growthbook plugin for e2e tests
    if (useRequestHeaders(["user-agent"])["user-agent"] === "playwright-test" || navigator?.webdriver) {
      return {
        provide: {
          featureFlags: new Proxy(
            {},
            {
              get() {
                return {
                  isOn: () => computed(() => false),
                  isOff: () => computed(() => true),
                };
              },
            },
          ),
          stringFeatureFlags: new Proxy(
            {},
            {
              get(_, key) {
                const baseline = stringFeatureKeys.find((f) => f.key === key)?.options[0];

                return {
                  getStringValue: () => computed(() => baseline),
                };
              },
            },
          ),
        },
      };
    }

    const {
      public: { growthBookApiHost, growthBookClientKey, growthBookEnableDevMode, growthBookEncryptionKey },
    } = useRuntimeConfig();

    const i18n = nuxt.$i18n as I18n;

    const { $pinia } = useNuxtApp();
    const userStore = useUserStore($pinia);
    const onboardingStore = useOnboardingStore($pinia);
    const biTrackingStore = useBiTrackingStore($pinia);
    const featureFlagStore = useFeatureFlagStore($pinia);

    const { trackGeneric } = useBiTrackingClient();

    configureCache({
      disableCache: true,
    });

    const growthbook = new GrowthBook({
      apiHost: growthBookApiHost,
      clientKey: growthBookClientKey,
      enableDevMode: growthBookEnableDevMode,
      decryptionKey: growthBookEncryptionKey,
      trackingCallback: async (experiment, result) => {
        if (nuxt.ssrContext) {
          // don't run the experiement tracking on the server due to event duplication
          return;
        }

        await trackGeneric("experiment.assigned", {
          experiment_id: experiment.key,
          variation_id: result.key,
        });
      },
    });

    async function setAttributes() {
      const attributes = {
        sex: onboardingStore.registrationParams.sex || undefined,
        goal: onboardingStore.registrationParams.goal || undefined,
        pro: !!(userStore.user && userStore.user.premium_type),
        country: onboardingStore.registrationParams.country || undefined,
        language: i18n.locale.value || undefined,
        session_id: biTrackingStore.sessionId || undefined,
        user_uuid: onboardingStore.registrationParams.userUuid || undefined,
        skipped_onboarding: onboardingStore.skippedOnboarding || undefined,
      };
      await growthbook.setAttributes(attributes);
    }

    await setAttributes();

    // Wait for features to be available
    await growthbook.init({ timeout: 2000 });

    // second growthbook instance to allow silent feature reading
    const _growthbook = new GrowthBook({
      attributes: growthbook.getAttributes(),
      features: growthbook.getFeatures(),
    });

    if (!nuxt.ssrContext) {
      watchEffect(async () => {
        await setAttributes();
        await _growthbook.setAttributes(growthbook.getAttributes());
      });
    }

    const featureFlags = featureKeys.reduce((acc, key) => {
      const isOn = (options?: FeatureFlagOptions) =>
        computed<boolean>(() => {
          const silentResult = _growthbook.isOn(key);
          const storeResult = featureFlagStore.featuresForcedValues?.[key];

          if (typeof storeResult === "boolean") {
            if (storeResult !== silentResult) {
              if (!options?.ignoreStore) {
                return storeResult;
              }
            }
          }
          if (options?.silent) {
            return silentResult;
          }
          return growthbook.isOn(key);
        });

      const isOff = (options?: FeatureFlagOptions) =>
        computed<boolean>(() => {
          return !isOn(options).value;
        });

      acc[key] = {
        isOn,
        isOff,
      };

      return acc;
    }, {} as GrowthBookFeatureFlags);

    const stringFeatureFlags = stringFeatureKeys.reduce((acc, feature) => {
      const baseline = feature.options[0];

      const getStringValue = (options?: FeatureFlagOptions) =>
        computed<(typeof feature.options)[number]>(() => {
          const storeResult = featureFlagStore.featuresForcedStringValues[feature.key];
          const silentGrowthBookValue = _growthbook.getFeatureValue<string>(feature.key, baseline);

          if (feature.options.includes(storeResult)) {
            if (storeResult !== silentGrowthBookValue) {
              if (!options?.ignoreStore) {
                return storeResult as (typeof feature.options)[number];
              }
            }
          }

          if (options?.silent) {
            return feature.options.includes(silentGrowthBookValue)
              ? (silentGrowthBookValue as (typeof feature.options)[number])
              : baseline;
          }

          const growthBookValue = growthbook.getFeatureValue<string>(feature.key, baseline);
          return feature.options.includes(growthBookValue)
            ? (growthBookValue as (typeof feature.options)[number])
            : baseline;
        });

      acc[feature.key] = {
        getStringValue,
        options: feature.options,
      };

      return acc;
    }, {} as GrowthBookStringFeatureFlags);

    return {
      provide: {
        featureFlags,
        stringFeatureFlags,
      },
    };
  },
});

interface localeToCountry {
  [key: string]: string;
}

export function useCountry() {
  const { locale } = useNuxtApp().$i18n;
  const headers = useRequestHeaders(["CF-IPCountry"]);

  function getCfIpCountryHeader() {
    return headers["cf-ipcountry"];
  }

  function bestGuessCountryByLocale() {
    const localeToCountry: localeToCountry = {
      de: "DE",
      tr: "TR",
      ru: "RU",
      it: "IT",
      fr: "FR",
      ko: "KR",
      pt: "BR",
      el: "GR",
      es: "ES",
      hu: "HU",
      nl: "NL",
      pl: "PL",
      en: "US",
      fi: "FI",
      zh: "CN",
      cs: "CZ",
      uk: "UA",
      ja: "JP",
      sv: "SE",
      da: "DK",
      nb: "NO",
      ro: "RO",
      id: "ID",
    };

    return localeToCountry[locale.value] ?? "US";
  }

  function getCountry(): string {
    const cfCountryHeader = getCfIpCountryHeader();

    // See: https://developers.cloudflare.com/fundamentals/reference/http-request-headers/#cf-ipcountry
    if (cfCountryHeader && cfCountryHeader !== "" && cfCountryHeader !== "XX" && cfCountryHeader !== "T1") {
      return cfCountryHeader;
    }

    return bestGuessCountryByLocale();
  }

  return {
    getCountry,
  };
}

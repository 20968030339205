
// @ts-nocheck


export const localeCodes =  [
  "cs",
  "da",
  "de",
  "el",
  "en",
  "es",
  "fi",
  "fr",
  "hu",
  "it",
  "ja",
  "ko",
  "nb",
  "nl",
  "pl",
  "pt",
  "ru",
  "sv",
  "tr",
  "zh"
]

export const localeLoaders = {
  "cs": [{ key: "../resources/translations/webapp-CS.json", load: () => import("../resources/translations/webapp-CS.json" /* webpackChunkName: "locale__app_resources_translations_webapp_CS_json" */), cache: true },
{ key: "../resources/translations/mobile-CS.json", load: () => import("../resources/translations/mobile-CS.json" /* webpackChunkName: "locale__app_resources_translations_mobile_CS_json" */), cache: true }],
  "da": [{ key: "../resources/translations/webapp-DA.json", load: () => import("../resources/translations/webapp-DA.json" /* webpackChunkName: "locale__app_resources_translations_webapp_DA_json" */), cache: true },
{ key: "../resources/translations/mobile-DA.json", load: () => import("../resources/translations/mobile-DA.json" /* webpackChunkName: "locale__app_resources_translations_mobile_DA_json" */), cache: true }],
  "de": [{ key: "../resources/translations/webapp-DE.json", load: () => import("../resources/translations/webapp-DE.json" /* webpackChunkName: "locale__app_resources_translations_webapp_DE_json" */), cache: true },
{ key: "../resources/translations/mobile-DE.json", load: () => import("../resources/translations/mobile-DE.json" /* webpackChunkName: "locale__app_resources_translations_mobile_DE_json" */), cache: true }],
  "el": [{ key: "../resources/translations/webapp-EL.json", load: () => import("../resources/translations/webapp-EL.json" /* webpackChunkName: "locale__app_resources_translations_webapp_EL_json" */), cache: true },
{ key: "../resources/translations/mobile-EL.json", load: () => import("../resources/translations/mobile-EL.json" /* webpackChunkName: "locale__app_resources_translations_mobile_EL_json" */), cache: true }],
  "en": [{ key: "../resources/translations/webapp-EN.json", load: () => import("../resources/translations/webapp-EN.json" /* webpackChunkName: "locale__app_resources_translations_webapp_EN_json" */), cache: true },
{ key: "../resources/translations/mobile-EN.json", load: () => import("../resources/translations/mobile-EN.json" /* webpackChunkName: "locale__app_resources_translations_mobile_EN_json" */), cache: true }],
  "es": [{ key: "../resources/translations/webapp-ES.json", load: () => import("../resources/translations/webapp-ES.json" /* webpackChunkName: "locale__app_resources_translations_webapp_ES_json" */), cache: true },
{ key: "../resources/translations/mobile-ES.json", load: () => import("../resources/translations/mobile-ES.json" /* webpackChunkName: "locale__app_resources_translations_mobile_ES_json" */), cache: true }],
  "fi": [{ key: "../resources/translations/webapp-FI.json", load: () => import("../resources/translations/webapp-FI.json" /* webpackChunkName: "locale__app_resources_translations_webapp_FI_json" */), cache: true },
{ key: "../resources/translations/mobile-FI.json", load: () => import("../resources/translations/mobile-FI.json" /* webpackChunkName: "locale__app_resources_translations_mobile_FI_json" */), cache: true }],
  "fr": [{ key: "../resources/translations/webapp-FR.json", load: () => import("../resources/translations/webapp-FR.json" /* webpackChunkName: "locale__app_resources_translations_webapp_FR_json" */), cache: true },
{ key: "../resources/translations/mobile-FR.json", load: () => import("../resources/translations/mobile-FR.json" /* webpackChunkName: "locale__app_resources_translations_mobile_FR_json" */), cache: true }],
  "hu": [{ key: "../resources/translations/webapp-HU.json", load: () => import("../resources/translations/webapp-HU.json" /* webpackChunkName: "locale__app_resources_translations_webapp_HU_json" */), cache: true },
{ key: "../resources/translations/mobile-HU.json", load: () => import("../resources/translations/mobile-HU.json" /* webpackChunkName: "locale__app_resources_translations_mobile_HU_json" */), cache: true }],
  "it": [{ key: "../resources/translations/webapp-IT.json", load: () => import("../resources/translations/webapp-IT.json" /* webpackChunkName: "locale__app_resources_translations_webapp_IT_json" */), cache: true },
{ key: "../resources/translations/mobile-IT.json", load: () => import("../resources/translations/mobile-IT.json" /* webpackChunkName: "locale__app_resources_translations_mobile_IT_json" */), cache: true }],
  "ja": [{ key: "../resources/translations/webapp-JA.json", load: () => import("../resources/translations/webapp-JA.json" /* webpackChunkName: "locale__app_resources_translations_webapp_JA_json" */), cache: true },
{ key: "../resources/translations/mobile-JA.json", load: () => import("../resources/translations/mobile-JA.json" /* webpackChunkName: "locale__app_resources_translations_mobile_JA_json" */), cache: true }],
  "ko": [{ key: "../resources/translations/webapp-KO.json", load: () => import("../resources/translations/webapp-KO.json" /* webpackChunkName: "locale__app_resources_translations_webapp_KO_json" */), cache: true },
{ key: "../resources/translations/mobile-KO.json", load: () => import("../resources/translations/mobile-KO.json" /* webpackChunkName: "locale__app_resources_translations_mobile_KO_json" */), cache: true }],
  "nb": [{ key: "../resources/translations/webapp-NB.json", load: () => import("../resources/translations/webapp-NB.json" /* webpackChunkName: "locale__app_resources_translations_webapp_NB_json" */), cache: true },
{ key: "../resources/translations/mobile-NB.json", load: () => import("../resources/translations/mobile-NB.json" /* webpackChunkName: "locale__app_resources_translations_mobile_NB_json" */), cache: true }],
  "nl": [{ key: "../resources/translations/webapp-NL.json", load: () => import("../resources/translations/webapp-NL.json" /* webpackChunkName: "locale__app_resources_translations_webapp_NL_json" */), cache: true },
{ key: "../resources/translations/mobile-NL.json", load: () => import("../resources/translations/mobile-NL.json" /* webpackChunkName: "locale__app_resources_translations_mobile_NL_json" */), cache: true }],
  "pl": [{ key: "../resources/translations/webapp-PL.json", load: () => import("../resources/translations/webapp-PL.json" /* webpackChunkName: "locale__app_resources_translations_webapp_PL_json" */), cache: true },
{ key: "../resources/translations/mobile-PL.json", load: () => import("../resources/translations/mobile-PL.json" /* webpackChunkName: "locale__app_resources_translations_mobile_PL_json" */), cache: true }],
  "pt": [{ key: "../resources/translations/webapp-PT.json", load: () => import("../resources/translations/webapp-PT.json" /* webpackChunkName: "locale__app_resources_translations_webapp_PT_json" */), cache: true },
{ key: "../resources/translations/mobile-PT.json", load: () => import("../resources/translations/mobile-PT.json" /* webpackChunkName: "locale__app_resources_translations_mobile_PT_json" */), cache: true }],
  "ru": [{ key: "../resources/translations/webapp-RU.json", load: () => import("../resources/translations/webapp-RU.json" /* webpackChunkName: "locale__app_resources_translations_webapp_RU_json" */), cache: true },
{ key: "../resources/translations/mobile-RU.json", load: () => import("../resources/translations/mobile-RU.json" /* webpackChunkName: "locale__app_resources_translations_mobile_RU_json" */), cache: true }],
  "sv": [{ key: "../resources/translations/webapp-SV.json", load: () => import("../resources/translations/webapp-SV.json" /* webpackChunkName: "locale__app_resources_translations_webapp_SV_json" */), cache: true },
{ key: "../resources/translations/mobile-SV.json", load: () => import("../resources/translations/mobile-SV.json" /* webpackChunkName: "locale__app_resources_translations_mobile_SV_json" */), cache: true }],
  "tr": [{ key: "../resources/translations/webapp-TR.json", load: () => import("../resources/translations/webapp-TR.json" /* webpackChunkName: "locale__app_resources_translations_webapp_TR_json" */), cache: true },
{ key: "../resources/translations/mobile-TR.json", load: () => import("../resources/translations/mobile-TR.json" /* webpackChunkName: "locale__app_resources_translations_mobile_TR_json" */), cache: true }],
  "zh": [{ key: "../resources/translations/webapp-ZH.json", load: () => import("../resources/translations/webapp-ZH.json" /* webpackChunkName: "locale__app_resources_translations_webapp_ZH_json" */), cache: true },
{ key: "../resources/translations/mobile-ZH.json", load: () => import("../resources/translations/mobile-ZH.json" /* webpackChunkName: "locale__app_resources_translations_mobile_ZH_json" */), cache: true }]
}

export const vueI18nConfigs = [
  () => import("../app/vuei18n.config.ts?hash=fa1733cd&config=1" /* webpackChunkName: "__app_vuei18n_config_ts_fa1733cd" */)
]

export const nuxtI18nOptions = {
  "restructureDir": "i18n",
  "experimental": {
    "localeDetector": "",
    "switchLocalePathLinkSSR": false,
    "autoImportTranslationFunctions": false,
    "typedPages": true,
    "typedOptionsAndMessages": false,
    "generatedLocaleFilePathFormat": "absolute"
  },
  "bundle": {
    "compositionOnly": true,
    "runtimeOnly": false,
    "fullInstall": true,
    "dropMessageCompiler": false,
    "optimizeTranslationDirective": true
  },
  "compilation": {
    "jit": true,
    "strictMessage": false,
    "escapeHtml": false
  },
  "customBlocks": {
    "defaultSFCLang": "json",
    "globalSFCScope": false
  },
  "vueI18n": "./app/vuei18n.config.ts",
  "locales": [
    {
      "code": "cs",
      "name": "Czech",
      "files": [
        "/app/resources/translations/webapp-CS.json",
        "/app/resources/translations/mobile-CS.json"
      ]
    },
    {
      "code": "da",
      "name": "Danish",
      "files": [
        "/app/resources/translations/webapp-DA.json",
        "/app/resources/translations/mobile-DA.json"
      ]
    },
    {
      "code": "de",
      "name": "German",
      "nativeName": "Deutsch",
      "files": [
        "/app/resources/translations/webapp-DE.json",
        "/app/resources/translations/mobile-DE.json"
      ]
    },
    {
      "code": "el",
      "name": "Greek",
      "files": [
        "/app/resources/translations/webapp-EL.json",
        "/app/resources/translations/mobile-EL.json"
      ]
    },
    {
      "code": "en",
      "name": "English",
      "nativeName": "English",
      "files": [
        "/app/resources/translations/webapp-EN.json",
        "/app/resources/translations/mobile-EN.json"
      ]
    },
    {
      "code": "es",
      "name": "Spanish",
      "nativeName": "Español",
      "files": [
        "/app/resources/translations/webapp-ES.json",
        "/app/resources/translations/mobile-ES.json"
      ]
    },
    {
      "code": "fi",
      "name": "Finnish",
      "files": [
        "/app/resources/translations/webapp-FI.json",
        "/app/resources/translations/mobile-FI.json"
      ]
    },
    {
      "code": "fr",
      "name": "French",
      "nativeName": "Français",
      "files": [
        "/app/resources/translations/webapp-FR.json",
        "/app/resources/translations/mobile-FR.json"
      ]
    },
    {
      "code": "hu",
      "name": "Hungarian",
      "files": [
        "/app/resources/translations/webapp-HU.json",
        "/app/resources/translations/mobile-HU.json"
      ]
    },
    {
      "code": "it",
      "name": "Italian",
      "nativeName": "Italiano",
      "files": [
        "/app/resources/translations/webapp-IT.json",
        "/app/resources/translations/mobile-IT.json"
      ]
    },
    {
      "code": "ja",
      "name": "Japanese",
      "files": [
        "/app/resources/translations/webapp-JA.json",
        "/app/resources/translations/mobile-JA.json"
      ]
    },
    {
      "code": "ko",
      "name": "Korean",
      "files": [
        "/app/resources/translations/webapp-KO.json",
        "/app/resources/translations/mobile-KO.json"
      ]
    },
    {
      "code": "nb",
      "name": "Norwegian",
      "files": [
        "/app/resources/translations/webapp-NB.json",
        "/app/resources/translations/mobile-NB.json"
      ]
    },
    {
      "code": "nl",
      "name": "Dutch",
      "files": [
        "/app/resources/translations/webapp-NL.json",
        "/app/resources/translations/mobile-NL.json"
      ]
    },
    {
      "code": "pl",
      "name": "Polish",
      "files": [
        "/app/resources/translations/webapp-PL.json",
        "/app/resources/translations/mobile-PL.json"
      ]
    },
    {
      "code": "pt",
      "name": "Portuguese",
      "nativeName": "Português",
      "files": [
        "/app/resources/translations/webapp-PT.json",
        "/app/resources/translations/mobile-PT.json"
      ]
    },
    {
      "code": "ru",
      "name": "Russian",
      "files": [
        "/app/resources/translations/webapp-RU.json",
        "/app/resources/translations/mobile-RU.json"
      ]
    },
    {
      "code": "sv",
      "name": "Swedish",
      "files": [
        "/app/resources/translations/webapp-SV.json",
        "/app/resources/translations/mobile-SV.json"
      ]
    },
    {
      "code": "tr",
      "name": "Turkish",
      "files": [
        "/app/resources/translations/webapp-TR.json",
        "/app/resources/translations/mobile-TR.json"
      ]
    },
    {
      "code": "zh",
      "name": "Chinese",
      "files": [
        "/app/resources/translations/webapp-ZH.json",
        "/app/resources/translations/mobile-ZH.json"
      ]
    }
  ],
  "defaultLocale": "en",
  "defaultDirection": "ltr",
  "routesNameSeparator": "___",
  "trailingSlash": false,
  "defaultLocaleRouteNameSuffix": "default",
  "strategy": "no_prefix",
  "lazy": true,
  "langDir": "../resources/translations",
  "detectBrowserLanguage": {
    "alwaysRedirect": false,
    "cookieCrossOrigin": false,
    "cookieDomain": null,
    "cookieKey": "yz_locale",
    "cookieSecure": true,
    "fallbackLocale": "",
    "redirectOn": "root",
    "useCookie": true
  },
  "differentDomains": false,
  "baseUrl": "",
  "customRoutes": "page",
  "pages": {},
  "skipSettingLocaleOnNavigate": false,
  "types": "composition",
  "debug": false,
  "parallelPlugin": false,
  "multiDomainLocales": false,
  "i18nModules": []
}

export const normalizedLocales = [
  {
    "code": "cs",
    "name": "Czech",
    "files": [
      {
        "path": "/app/resources/translations/webapp-CS.json"
      },
      {
        "path": "/app/resources/translations/mobile-CS.json"
      }
    ]
  },
  {
    "code": "da",
    "name": "Danish",
    "files": [
      {
        "path": "/app/resources/translations/webapp-DA.json"
      },
      {
        "path": "/app/resources/translations/mobile-DA.json"
      }
    ]
  },
  {
    "code": "de",
    "name": "German",
    "nativeName": "Deutsch",
    "files": [
      {
        "path": "/app/resources/translations/webapp-DE.json"
      },
      {
        "path": "/app/resources/translations/mobile-DE.json"
      }
    ]
  },
  {
    "code": "el",
    "name": "Greek",
    "files": [
      {
        "path": "/app/resources/translations/webapp-EL.json"
      },
      {
        "path": "/app/resources/translations/mobile-EL.json"
      }
    ]
  },
  {
    "code": "en",
    "name": "English",
    "nativeName": "English",
    "files": [
      {
        "path": "/app/resources/translations/webapp-EN.json"
      },
      {
        "path": "/app/resources/translations/mobile-EN.json"
      }
    ]
  },
  {
    "code": "es",
    "name": "Spanish",
    "nativeName": "Español",
    "files": [
      {
        "path": "/app/resources/translations/webapp-ES.json"
      },
      {
        "path": "/app/resources/translations/mobile-ES.json"
      }
    ]
  },
  {
    "code": "fi",
    "name": "Finnish",
    "files": [
      {
        "path": "/app/resources/translations/webapp-FI.json"
      },
      {
        "path": "/app/resources/translations/mobile-FI.json"
      }
    ]
  },
  {
    "code": "fr",
    "name": "French",
    "nativeName": "Français",
    "files": [
      {
        "path": "/app/resources/translations/webapp-FR.json"
      },
      {
        "path": "/app/resources/translations/mobile-FR.json"
      }
    ]
  },
  {
    "code": "hu",
    "name": "Hungarian",
    "files": [
      {
        "path": "/app/resources/translations/webapp-HU.json"
      },
      {
        "path": "/app/resources/translations/mobile-HU.json"
      }
    ]
  },
  {
    "code": "it",
    "name": "Italian",
    "nativeName": "Italiano",
    "files": [
      {
        "path": "/app/resources/translations/webapp-IT.json"
      },
      {
        "path": "/app/resources/translations/mobile-IT.json"
      }
    ]
  },
  {
    "code": "ja",
    "name": "Japanese",
    "files": [
      {
        "path": "/app/resources/translations/webapp-JA.json"
      },
      {
        "path": "/app/resources/translations/mobile-JA.json"
      }
    ]
  },
  {
    "code": "ko",
    "name": "Korean",
    "files": [
      {
        "path": "/app/resources/translations/webapp-KO.json"
      },
      {
        "path": "/app/resources/translations/mobile-KO.json"
      }
    ]
  },
  {
    "code": "nb",
    "name": "Norwegian",
    "files": [
      {
        "path": "/app/resources/translations/webapp-NB.json"
      },
      {
        "path": "/app/resources/translations/mobile-NB.json"
      }
    ]
  },
  {
    "code": "nl",
    "name": "Dutch",
    "files": [
      {
        "path": "/app/resources/translations/webapp-NL.json"
      },
      {
        "path": "/app/resources/translations/mobile-NL.json"
      }
    ]
  },
  {
    "code": "pl",
    "name": "Polish",
    "files": [
      {
        "path": "/app/resources/translations/webapp-PL.json"
      },
      {
        "path": "/app/resources/translations/mobile-PL.json"
      }
    ]
  },
  {
    "code": "pt",
    "name": "Portuguese",
    "nativeName": "Português",
    "files": [
      {
        "path": "/app/resources/translations/webapp-PT.json"
      },
      {
        "path": "/app/resources/translations/mobile-PT.json"
      }
    ]
  },
  {
    "code": "ru",
    "name": "Russian",
    "files": [
      {
        "path": "/app/resources/translations/webapp-RU.json"
      },
      {
        "path": "/app/resources/translations/mobile-RU.json"
      }
    ]
  },
  {
    "code": "sv",
    "name": "Swedish",
    "files": [
      {
        "path": "/app/resources/translations/webapp-SV.json"
      },
      {
        "path": "/app/resources/translations/mobile-SV.json"
      }
    ]
  },
  {
    "code": "tr",
    "name": "Turkish",
    "files": [
      {
        "path": "/app/resources/translations/webapp-TR.json"
      },
      {
        "path": "/app/resources/translations/mobile-TR.json"
      }
    ]
  },
  {
    "code": "zh",
    "name": "Chinese",
    "files": [
      {
        "path": "/app/resources/translations/webapp-ZH.json"
      },
      {
        "path": "/app/resources/translations/mobile-ZH.json"
      }
    ]
  }
]

export const NUXT_I18N_MODULE_ID = "@nuxtjs/i18n"
export const parallelPlugin = false
export const isSSG = false
export const hasPages = true

export const DEFAULT_DYNAMIC_PARAMS_KEY = "nuxtI18nInternal"
export const DEFAULT_COOKIE_KEY = "i18n_redirected"
export const SWITCH_LOCALE_PATH_LINK_IDENTIFIER = "nuxt-i18n-slp"

import type {
  UpdateUserParams,
  RegisterUserParams,
  UpdatePasswordParams,
  UserSubscription,
  CancelSubscriptionParams,
  UserGoals,
  UpdateUserGoalsParams,
  UserAccountAPI,
} from "~/types/user";
import { UserAccountAPISchema, UserGoalsSchema } from "~/types/user";

export function useBackendApiAppDomainClient() {
  const { baseUrl } = useBackendApiAppDomainConfig();
  const { isActive } = useUserSubscription();
  const { fetchAuth, useNuxtFetchAuth } = useAuthApiClient();
  const { fetch } = useRequest(baseUrl);
  const dayjs = useDayjs();

  function registerUser(body: RegisterUserParams) {
    return fetch("/v15/user", {
      method: "post",
      body,
    });
  }

  function useFetchUser() {
    return useNuxtFetchAuth<UserAccountAPI>("/v15/user", {
      immediate: false,
      watch: false,
      transform: (data) => UserAccountAPISchema.parse(data),
    });
  }

  function useFetchUserGoals() {
    return useNuxtFetchAuth<UserGoals>("/v15/user/goals", {
      immediate: false,
      watch: false,
      query: {
        date: dayjs().format("YYYY-MM-DD"),
      },
      transform: (data) => UserGoalsSchema.parse(data),
    });
  }

  function updateUserGoals(body: UpdateUserGoalsParams) {
    return fetchAuth("/v15/user/goals", {
      method: "patch",
      body,
    });
  }

  function updateUser(body: Partial<UpdateUserParams>) {
    return fetchAuth("/v15/user", {
      method: "patch",
      body,
    });
  }

  function updatePassword(body: UpdatePasswordParams) {
    return fetchAuth("/v15/user/password", {
      method: "put",
      body,
    });
  }

  function deleteUser() {
    return fetchAuth("/v15/user", {
      method: "delete",
    });
  }

  function useFetchUserSubscriptions() {
    return useNuxtFetchAuth<UserSubscription[]>("/v15/user/subscription", {
      transform: (res) => {
        function _isActive(userSubscription: UserSubscription) {
          return isActive(userSubscription) ? 1 : 0;
        }
        return res.sort((a, b) => _isActive(b) - _isActive(a));
      },
    });
  }

  function cancelStripeSubscription(body: CancelSubscriptionParams) {
    return fetchAuth("/v15/user/subscription/stripe", {
      method: "delete",
      body,
    });
  }

  function cancelBraintreeSubscription(body: CancelSubscriptionParams) {
    return fetchAuth("/v15/user/subscription/braintree", {
      method: "delete",
      body,
    });
  }

  function sendResetToken(email: string) {
    return fetch("/v15/user/send-reset-token", {
      method: "post",
      body: { email },
    });
  }

  function getValidVoucherCodeDetails(code: string) {
    return fetch(`/v15/price-segments/voucher/${code}`);
  }

  function redeemCouponCode(code: string) {
    return fetchAuth(`/v15/user/coupon-codes/redeem`, {
      method: "post",
      body: { coupon_code: code },
    });
  }

  return {
    registerUser,
    cancelStripeSubscription,
    cancelBraintreeSubscription,
    useFetchUser,
    useFetchUserGoals,
    updateUserGoals,
    deleteUser,
    updateUser,
    updatePassword,
    useFetchUserSubscriptions,
    sendResetToken,
    getValidVoucherCodeDetails,
    redeemCouponCode,
  };
}

import { useAwinStore } from "~/stores/useAwinStore";
import appConfig from "~/app.config";

const AWIN_TRACKING_URL = "https://www.awin1.com/sread.php";

export function useAwinTrackingClient() {
  const { $pinia } = useNuxtApp();
  const awinStore = useAwinStore($pinia);
  const { fetch } = useRequest();
  const dayjs = useDayjs();
  const basketFreezeOverrideList = appConfig.awin.basketFreezeOverrideList;

  const {
    public: { awinTestMode },
  } = useRuntimeConfig();

  function getCurrentAwc() {
    return isBasketFreezeActive() ? awinStore.frozenAwc : awinStore.awc;
  }

  function isBasketFreezeActive() {
    return (
      awinStore.frozenAwc !== "" &&
      awinStore.frozenAwc !== awinStore.awc &&
      dayjs().isBefore(awinStore.basketFreezeExpires)
    );
  }

  function handleBasketFreeze() {
    // If the affiliateId is in the override list, the current basket freeze will be ignored
    if (isBasketFreezeActive() && !basketFreezeOverrideList.includes(awinStore.affiliateId)) {
      return;
    }

    awinStore.basketFreezeExpires = dayjs().add(20, "minutes").format();
    awinStore.frozenAwc = awinStore.awc;
    awinStore.$persist();
  }

  function trackPurchase(orderReference: string, currencyCode: string, amount: number, voucherCode: string) {
    if (!awinStore.awc) return;

    const totalAmount = (amount / 100).toFixed(2);

    const trackingParameters = {
      tt: "ss",
      tv: "2",
      merchant: awinStore.merchantId,
      amount: totalAmount,
      ch: awinStore.source,
      parts: `STANDARD:${totalAmount}`,
      vc: voucherCode,
      cr: currencyCode,
      ref: orderReference,
      testmode: awinTestMode === "true" ? "1" : "0",
      cks: getCurrentAwc(),
    };

    return fetch(AWIN_TRACKING_URL, {
      method: "GET",
      query: trackingParameters,
    });
  }

  return {
    trackPurchase,
    isBasketFreezeActive,
    getCurrentAwc,
    handleBasketFreeze,
  };
}

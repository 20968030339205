import handleQueryParameters from "../middleware/manuallyGloballyRegistered/handleQueryParameters";
import redirectFromRoot from "../middleware/manuallyGloballyRegistered/redirectFromRoot";
import setDayJSLocale from "../middleware/manuallyGloballyRegistered/setDayJSLocale";
import importWebFonts from "../middleware/manuallyGloballyRegistered/importWebFonts";
import loadInitialData from "../middleware/manuallyGloballyRegistered/loadInitialData";
import handleFreeVoucher from "../middleware/manuallyGloballyRegistered/handleFreeVoucher";

export default defineNuxtPlugin({
  name: "04RegisterMiddlewares",
  dependsOn: ["i18n:plugin", "i18n:plugin:switch-locale-path-ssr", "01I18n", "pinia", "pinia-plugin-persistedstate"],
  setup() {
    addRouteMiddleware("handleQueryParameters", handleQueryParameters, { global: true });
    addRouteMiddleware("redirectFromRoot", redirectFromRoot, { global: true });
    addRouteMiddleware("setDayJSLocale", setDayJSLocale, { global: true });
    addRouteMiddleware("importWebFonts", importWebFonts, { global: true });
    addRouteMiddleware("loadInitialData", loadInitialData, { global: true });
    addRouteMiddleware("handleFreeVoucher", handleFreeVoucher, { global: true });
  },
});

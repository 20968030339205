import type { I18n } from "~/types/i18n";
import { formatLink, fillPlaceholderValues } from "~/utils/text";

export default defineNuxtPlugin({
  name: "01I18n",
  dependsOn: ["i18n:plugin", "i18n:plugin:switch-locale-path-ssr", "pinia", "pinia-plugin-persistedstate"],
  setup(nuxt) {
    const i18n = nuxt.$i18n as I18n;
    const nativeTranslateFn = i18n.t;

    function extendedTranslateFn(
      key: string,
      params: string[],
      targetBlank?: string,
      link?: { [key: string]: string },
    ) {
      const translatedText = nativeTranslateFn(key);
      const translatedTextWithFormattedLinks = formatLink(translatedText, targetBlank, link);
      if (params && params.length > 0) {
        return fillPlaceholderValues(translatedTextWithFormattedLinks, params);
      }
      return translatedTextWithFormattedLinks;
    }

    // @ts-expect-error: Type mismatch
    i18n.t = extendedTranslateFn;

    i18n.defaultUnitLength = computed(() => (i18n.locale.value === "en" ? "in" : "cm"));
    i18n.defaultUnitMass = computed(() => (i18n.locale.value === "en" ? "lb" : "kg"));
    i18n.defaultUnitEnergy = computed(() => (i18n.locale.value === "en" ? "kj" : "kcal"));
    i18n.defaultUnitGlucose = computed(() => (i18n.locale.value === "en" ? "mmoll" : "mgdl"));
    i18n.defaultUnitServing = computed(() => (i18n.locale.value === "en" ? "oz" : "g"));

    return {
      provide: {
        t: extendedTranslateFn,
      },
    };
  },
});

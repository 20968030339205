import type { FetchError } from "ofetch";
import type { Sex } from "~/types/onboarding/registrationParams";
import type { GoalTypeAbbr } from "~/types/onboarding/onboardingGoal";

export type UtmParameters = {
  utm_medium?: string;
  utm_source?: string;
  utm_campaign?: string;
  utm_term?: string;
  utm_content?: string;
};

interface BiTrackingDate {
  isoDateTime: string;
}

export type LoginStatus = "guest" | "token" | "user_login";

export type ExperimentAssingedGenericEventParams = {
  name: "experiment.assigned";
  experiment_id: string;
  variation_id: string;
};

export type PostalcodeInvalidGenericEventParams = {
  name: "postal_code.invalid";
  input?: string;
};

export interface ImpressionEventParams {
  name: string;
  webFunnelId?: string;
  loginStatus?: LoginStatus;
}

export interface PurchaseEventParams {
  sku: string;
  period: string;
  currency: string;
  gateway: "stripe";
  amountCustomerFacingCurrency: number;
  switchedSession?: boolean;
  paymentMethod: string;
}

export enum BiTrackingEventType {
  IMPRESSION = "Impression",
  PURCHASE = "Purchase",
  ACTION = "Action",
  GENERIC = "Generic",
}

export interface BiTrackingEvent {
  type: BiTrackingEventType;
  sessionId: string;
  date: BiTrackingDate;
  properties?: string;
}

export interface GenericEvent extends BiTrackingEvent {
  type: BiTrackingEventType.GENERIC;
  name: string;
}

export interface ActionEvent extends BiTrackingEvent {
  type: BiTrackingEventType.ACTION;
  name: string;
}

export interface ImpressionEvent extends BiTrackingEvent {
  type: BiTrackingEventType.IMPRESSION;
  name: string;
  loginStatus?: LoginStatus;
}

export interface PurchaseEvent extends BiTrackingEvent {
  type: BiTrackingEventType.PURCHASE;
  duration: {
    isoPeriod: string;
  };
  sku: string;
  currency: string;
  gateway: "stripe";
  amountCustomerFacingCurrency: number;
  properties?: string;
}

export interface BiTrackingHeader {
  age: number | null;
  platform: string | null;
  language: string | null;
  country: string | null;
  sex: Sex | null;
  overallGoal: GoalTypeAbbr | null;
  weightGoal: number | null;
  weightCurrent: number | null;
  bodyMassIndex: number | null;
  userUUID: string | null;
  properties: string;
}

export type BiTrackingEvents = Array<ImpressionEvent | PurchaseEvent | ActionEvent | GenericEvent>;

export type BiTrackingRequestBody = Array<{
  header: Partial<BiTrackingHeader>;
  events: BiTrackingEvents;
}>;

export interface BiTrackingResponseData extends FetchError {
  message: string;
  timestamp?: string;
  status?: number;
  error?: string;
  path?: string;
}
